@keyframes expandRight {
    0% {
      width: 0;
    }
    100% {
      width: 100%;
    }
  }
  
  .sidebarAnime {
    animation: expandRight 0.2s ease-out forwards;
  }
  .fadeoutSidebarAnime {
    animation: expandRight 0.2s ease-out forwards reverse;
  }
.message-channel-opener {
    animation: message-channel-opener 0.1s linear forwards;
}
.message-channel-closer {
    animation: message-channel-closer 0.1s linear forwards;
}

@keyframes message-channel-opener {
    0% {
        rotate: 0deg;
    }
    100% {
        rotate: 90deg;
    }
}

@keyframes message-channel-closer {
    0% {
        rotate: 90deg;
    }
    100% {
        rotate: 0deg;
    }
}
.replying_message{
    position:relative;
    display:inline-block;
 }
 
.replying_message::before{
    content:"";
    border-left: solid 2px gray;
	border-top: solid 2px gray;
    width: 40px;
	height: 10px;
    border-top-left-radius: 0.25rem; 
    position:absolute;
    top:10px;
    right:4px;
 }
 @media (max-width: 1024px) {
    .replying_message::before {
        width: 30px;
        height: 10px;
        top:10px;
        right:2px;
    }
}

@media (max-width: 768px) {
    .replying_message::before {
        width: 30px;
        height: 10px;
        top:10px;
        right:2px;
    }
}

@media (max-width: 640px) {
    .replying_message::before {
        width: 18px;
        height: 10px;
        top:10px;
        right:2px;
    }
}




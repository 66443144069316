/* #ff7f50 */
/* #ff1493 */
/* #2D2943 */

.custom-text-field {
  background-color: #38373D;
  color: #b8c0dc;
  padding: 10px 12px;
  /* border-radius: 5px; */
  border: 3px solid transparent; /* 透明のボーダーを設定 */
  position: relative;
  z-index: 1;
  transition: background-color 0.3s ease-in-out;
  border: 1px solid #3b4154;
}

.custom-text-field::placeholder {
  color: #b0b9c2;
  opacity: 0.5;
  font-size: 14px;
}

.custom-text-field::before {
  content: "";
  position: absolute;
  top: -3px;
  right: -3px;
  bottom: -3px;
  left: -3px;
  z-index: -1;
  border-radius: 8px; /* 元のborder-radiusよりも3px大きく */
  background: linear-gradient(45deg, rgb(255, 85, 113), rgb(255, 172, 18));
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.custom-text-field:hover:not(:focus)::before {
  opacity: 1;
}

.custom-text-field:focus {
  background-color: #171920;
  outline: none;
  border: 1px solid #72767a;
}

.custom-label {
  background: linear-gradient(45deg, rgb(255, 85, 113), rgb(255, 172, 18));
}

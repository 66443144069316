.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes slideInRight {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
.slideInRight {
  animation: slideInRight 1s; /* アニメーション速度を1秒に設定 */
}

/* ハンバーガーメニューアイコンのスタイル */
.hamburger-icon {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 24px; /* アイコンの幅を調整 */
  height: 18px; /* アイコンの高さを調整 */
  cursor: pointer; /* クリック可能な要素として設定 */

  /* 上方向のマージンを追加 */
  margin-top: 16px; /* 適切な値に調整 */

  /* 左方向のマージンを追加 */
  margin-left: 16px; /* 適切な値に調整 */
}

.bar {
  width: 100%;
  height: 3px; /* バーの高さを調整 */
  background-color: white; /* バーの色を設定 */
}

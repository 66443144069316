.custom-radio {
    display: none;
    position: absolute;
}
.radio-text {
    position: relative;
    display: flex;
    align-items: center;
    box-sizing: border-box;
  }
.radio-text::before {
    margin-top: 2px;
    margin-right: 8px;
    content: '';
    display: block;
    border-radius: 50%;
    box-sizing: border-box;
    width: 16px;
    height: 16px;
    background-color: #969696;
    left: 0px;
    bottom: 0px;
}
  /* ラジオボタンが選択されたときのスタイルを変更する */

/* .radio-text::after {
    content: '';
    display: block;
    position: absolute;

    border-radius: 50%;
    width: 16px;
    height: 16px;
    left:0px;
    bottom: 4px;
    background-color: orange;
} */

.custom-radio:checked+.radio-text::after { 
    content: '';
    margin-top: 2px;
    position: absolute;
    box-sizing: border-box;
    left: 0px;
    bottom: 1px;
    display: block;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    background-color: #E1017F;
  }
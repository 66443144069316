.loading-checkbox {
    cursor: pointer;
    color: gray;
}
.loading-checkbox input {
    display: none;
}
.loading-checkbox span {
    position: relative;
    display: block;
    width: 16px;
    height: 16px;
    border: 1px solid gray;
    border-radius: 5px;
}
.loading-checkbox input:checked + span {
    border-color: #FEFEFE;
}
.loading-checkbox input:checked ~ p {
    color: #FEFEFE;
}
.loading-checkbox input:checked + span::before {
    content: '';
    position: absolute;
    top: 0px;
    left: 1px;
    display: block;
    width: 18px;
    height: 7px;
    border-left-width: 2px;
    border-bottom-width: 2px;
    border-color: white;
    transform: rotate(-45deg);
}

.register-checkbox {
    cursor: pointer;
    color: black;
}
.register-checkbox input {
    display: none;
}
.register-checkbox span {
    position: relative;
    display: block;
    background-color: white;
    width: 16px;
    height: 16px;
    border: 1px solid black;
    border-radius: 5px;
}
.register-checkbox input:checked + span {
    border-color: black;
}
.register-checkbox input:checked ~ p {
    background: linear-gradient(to right, #EA7B57, #DF147F);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
}
.register-checkbox input:checked + span::before {
    content: '';
    position: absolute;
    top: 0px;
    left: 1px;
    display: block;
    width: 18px;
    height: 7px;
    border-left-width: 3px;
    border-bottom-width: 3px;
    border-image: linear-gradient(to right, #EA7B57, #DF147F) 1;
    transform: rotate(-45deg);
}

.unyte-check {
    content: '';
    position: absolute;
    top: 0px;
    left: 1px;
    display: block;
    width: 64px;
    height: 40px;
    border-left-width: 2px;
    border-bottom-width: 2px;
    border-color: white;
    transform: rotate(-45deg);
}

.custom-check-icon{
    /* border: 5px solid #999; */
    padding: 20px;
    position: relative;
    margin-bottom: 10px;
}


.custom-check-icon::before,.custom-check-icon::after{
    content: "";
    display: block;
    height: 12px;
    width: 48px;
    border-radius: 10px;
    transform: rotate(45deg);
    position: absolute;
    left: calc(36% + 14px);
    background: linear-gradient(to right,#EB7954, #E85C6A) ;
    top: 0;
    bottom: 1px;
    margin: auto;
}
.custom-check-icon::after{
    transform: rotate(-45deg);
    width: 80px;
    left: calc(41% + 16px);
    bottom: 20px;
    background: linear-gradient(to right,#E85C6A, #CD1174) ;

}
.custom-check-icon::before{
    animation: 1s custom-check-icon;
}
.custom-check-icon::after{
    animation: 1s custom-check-icon-2;
}
@keyframes custom-check-icon{
    0%{
        width: 22px;
        left: calc(41% + 14px);
    }
    30%{
        width: 48px;
        left: calc(41% + 16px);
    }
}
@keyframes custom-check-icon-2{
    0%{
        width: 0px;
        left: calc(41% + 16px);
        bottom: 17px;
    }
    30%{
        width: 5px;
        left: calc(41% + 16px);
        bottom: 17px;
    }
    100%{
        width: 80px;
        left: calc(41% + 15px);
        bottom: 20px;
    }
}

.circle-anime {
    animation: 2s infinite circle-anime;
}

@keyframes circle-anime {
    0% {
    }
    12.5% {
        transform: rotate(45deg);
    }
    25% {
        transform: rotate(90deg);
    }
    37.5% {
        transform: rotate(135deg);
    }
    50% {
        transform: rotate(180deg);
    }
    62.5% {
        transform: rotate(225deg);
    }
    75% {
        transform: rotate(270deg);
    }
    87.5% {
        transform: rotate(315deg);
    }
    100% {
        transform: rotate(360deg);
    }
}